/*--------------------------------------------------
	Ajax Page Transition
---------------------------------------------------*/

main {
  overflow: hidden;
}
main .cd-main-content > div {
  height: 100%;
  min-height: 100vh;
}
main .cd-main-content {
  width: 100%;
}
main .cd-main-content.cd-index > div {
  vertical-align: middle;
}
main .cd-main-content.cd-index::after {
  background-color: rgba(64, 137, 166, 0);
  background-image: -webkit-linear-gradient(
    bottom,
    #4089a6,
    rgba(64, 137, 166, 0)
  );
  background-image: linear-gradient(to top, #4089a6, rgba(64, 137, 166, 0));
}
main .cd-main-content.cd-index::before {
  background-color: rgba(64, 137, 166, 0);
  background-image: -webkit-linear-gradient(
    top,
    #4089a6,
    rgba(64, 137, 166, 0)
  );
  background-image: linear-gradient(to bottom, #4089a6, rgba(64, 137, 166, 0));
}
main .cd-main-content.cd-about {
  background-color: #fff;
}
main .cd-main-content.cd-about > div {
  padding-top: 50px;
}
main .cd-main-content.cd-about::after {
  background-color: rgba(40, 48, 64, 0);
  background-image: -webkit-linear-gradient(
    bottom,
    #283040,
    rgba(40, 48, 64, 0)
  );
  background-image: linear-gradient(to top, #283040, rgba(40, 48, 64, 0));
}
main .cd-main-content.cd-about::before {
  background-color: rgba(40, 48, 64, 0);
  background-image: -webkit-linear-gradient(top, #283040, rgba(40, 48, 64, 0));
  background-image: linear-gradient(to bottom, #283040, rgba(40, 48, 64, 0));
}
.cd-cover-layer,
.cd-cover-layer.invisible {
  background-color: transparent;
}
.cd-cover-layer {
  position: fixed;
  z-index: 5000;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  height: 101%;
  width: 101%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.4s 0.4s, visibility 0s 0.4s;
  -moz-transition: opacity 0.4s 0.4s, visibility 0s 0.4s;
  transition: opacity 0.6s 0s, visibility 0s 0.4s;
}
.page-is-changing .cd-cover-layer {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
  transition: opacity 0.3s 0.3s, visibility 0s 0s;
}

/*--------------------------------------------------
	Flex Nav
---------------------------------------------------*/

.flexnav,
.flexnav li ul {
  width: 100%;
  margin: 0;
}
.flexnav,
.flexnav li {
  margin: 0;
}
.flexnav li,
.flexnav li ul li {
  font-size: 100%;
}
.flexnav .touch-button:hover,
.menu-button {
  cursor: pointer;
}
.flexnav {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  transition: none;
  -webkit-transform-style: preserve-3d;
  overflow: hidden;
  max-height: 0;
}
.flexnav.opacity {
  opacity: 0;
}
.flexnav.flexnav-show {
  max-height: 2000px;
  opacity: 1;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.flexnav.one-page {
  position: fixed;
  top: 50px;
  right: 5%;
  max-width: 200px;
}
.flexnav li,
.flexnav li a,
.flexnav li ul li {
  position: relative;
  overflow: hidden;
}
.flexnav li a {
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  color: #222;
  display: block;
  padding: 20px 0;
  z-index: 2;
}
.oldie .flexnav li ul.open ul.open,
.oldie .flexnav ul li:hover ul {
  margin-left: 100%;
  top: 0;
}
.flexnav li ul.flexnav-show li,
.oldie .flexnav,
.oldie .flexnav li,
.oldie .flexnav li a {
  overflow: visible;
}
.flexnav li ul li a {
  background: #fff;
  display: block;
  padding: 20px;
}
.flexnav ul li ul li a {
  background: #bfbfbc;
}
.flexnav ul li ul li ul li a {
  background: #cbcbc9;
}
.flexnav .touch-button {
  background: rgba(0, 0, 0, 0);
  display: inline-block;
  height: 60px;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  width: 60px;
  z-index: 999;
}
.flexnav .touch-button .navicon {
  position: relative;
  top: 1.8em;
  font-size: 12px;
  color: #666;
}
.menu-button {
  background: 0 0;
  border-bottom: medium none;
  box-sizing: border-box;
  color: #222;
  display: block;
  font-size: 14px;
  font-weight: 400;
  height: 60px;
  line-height: 60px;
  padding: 0 50px;
  position: relative;
  text-align: right;
  text-transform: uppercase;
  z-index: 0;
}
.no-button .menu-button {
  padding-right: 0;
}
.menu-button.one-page {
  position: fixed;
  top: 0;
  right: 5%;
  padding-right: 45px;
}
.menu-button .touch-button {
  background: 0 0;
  display: none;
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  text-align: center;
}
.menu-button .touch-button .navicon {
  font-size: 16px;
  position: relative;
  top: 1em;
  color: #666;
}
@media all and (min-width: 1025px) {
  .flexnav li a,
  .flexnav li ul li a {
    border-bottom: none;
  }
  .flexnav,
  .flexnav li {
    overflow: visible;
  }
  body.one-page {
    padding-top: 70px;
  }
  .flexnav.opacity {
    opacity: 1;
  }
  .flexnav.one-page {
    top: 0;
    right: auto;
    max-width: 1080px;
  }
  .flexnav li {
    position: relative;
    list-style: none;
    float: left;
    display: block;
    background-color: #a6a6a2;
  }
  .flexnav li a {
    border-left: 1px solid #acaca1;
  }
  .flexnav li > ul {
    position: absolute;
    top: auto;
    left: 0;
  }
  .flexnav li ul li > ul,
  .flexnav li ul.open ul.open {
    margin-left: 100%;
    top: 0;
  }
  .flexnav li > ul li {
    width: 100%;
  }
  .flexnav li ul.open {
    display: block;
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }
  .flexnav li ul.open li {
    overflow: visible;
    max-height: 100px;
  }
  .menu-button {
    display: none;
  }
}
.oldie body.one-page {
  padding-top: 70px;
}
.oldie .flexnav.one-page {
  top: 0;
  right: auto;
  max-width: 1080px;
}
.oldie .flexnav li {
  position: relative;
  list-style: none;
  float: left;
  display: block;
  background-color: #a6a6a2;
  width: 20%;
  min-height: 50px;
}
.oldie .flexnav li:hover > ul {
  display: block;
  width: 100%;
  overflow: visible;
}
.oldie .flexnav li:hover > ul li {
  width: 100%;
  float: none;
}
.oldie .flexnav li a {
  border-left: 1px solid #acaca1;
  border-bottom: none;
}
.oldie .flexnav li > ul {
  background: #acaca1;
  position: absolute;
  top: auto;
  left: 0;
  display: none;
  z-index: 1;
  overflow: visible;
}
.oldie .flexnav li ul.open,
.oldie .flexnav li ul.open ul.open {
  display: block;
  width: 100%;
  overflow: visible;
}
.oldie .flexnav li ul li ul {
  top: 0;
}
.oldie .flexnav li ul li a {
  border-bottom: none;
}
.oldie .flexnav li ul.open li {
  width: 100%;
}
.oldie .menu-button {
  display: none;
}
.oldie.ie7 .flexnav li {
  width: 19.9%;
}

/*--------------------------------------------------
	Swiper Slider
---------------------------------------------------*/

.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.swiper-container-no-flexbox .swiper-slide {
  float: left;
}
.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}
.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}
.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}
/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform, height;
}
/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}
/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}
/* Arrows */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  -moz-background-size: 27px 44px;
  -webkit-background-size: 27px 44px;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto;
}
.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
}
.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
/* Pagination Styles */
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}
/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}
/* Bullets */
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}
button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}
.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}
.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}
.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000;
}
.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  -moz-transform: translate3d(0px, -50%, 0);
  -o-transform: translate(0px, -50%);
  -ms-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0);
}
.swiper-container-vertical
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 5px 0;
  display: block;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 5px;
}
/* Progress */
.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}
.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
}
.swiper-container-rtl
  .swiper-pagination-progress
  .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top;
}
.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}
.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}
.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5);
}
.swiper-pagination-progress.swiper-pagination-white
  .swiper-pagination-progressbar {
  background: #fff;
}
.swiper-pagination-progress.swiper-pagination-black
  .swiper-pagination-progressbar {
  background: #000;
}
/* 3D Container */
.swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px;
}
.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}
.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0))
  );
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(
    right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(
    right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(
    right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Firefox 16+, IE10, Opera 12.50+ */
}
.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0))
  );
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(
    left,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Firefox 16+, IE10, Opera 12.50+ */
}
.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0))
  );
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Firefox 16+, IE10, Opera 12.50+ */
}
.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 0, 0, 0.5)),
    to(rgba(0, 0, 0, 0))
  );
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  /* Firefox 16+, IE10, Opera 12.50+ */
}
/* Coverflow */
.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  -ms-perspective: 1200px;
}
/* Cube + Flip */
.swiper-container-cube,
.swiper-container-flip {
  overflow: visible;
}
.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}
.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}
/* Cube */
.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}
.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}
.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}
/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}
.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}
.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}
.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}
.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}
.swiper-scrollbar-cursor-drag {
  cursor: move;
}
/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}
.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}
.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}
@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------
	Owl Carousel
---------------------------------------------------*/

.owl-carousel,
.owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}
.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden;
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: none;
}
.no-js .owl-carousel,
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}
.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.owl-height {
  transition: height 0.5s ease-in-out;
}
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 0.4s ease;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  /* background:url(owl.video.play.png) no-repeat; */
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 0.1s ease;
}
.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.4s ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/*--------------------------------------------------
	Magnific Popup
---------------------------------------------------*/

.mfp-bg,
.mfp-wrap {
  position: fixed;
  left: 0;
  top: 0;
}
.mfp-bg,
.mfp-container,
.mfp-wrap {
  height: 100%;
  width: 100%;
}
.mfp-arrow:after,
.mfp-arrow:before,
.mfp-container:before,
.mfp-figure:after {
  content: "";
}
.mfp-bg {
  z-index: 1042;
  overflow: hidden;
  background: #0b0b0b;
  opacity: 0.8;
}
.mfp-arrow,
.mfp-arrow:focus,
.mfp-arrow:hover,
.mfp-close,
.mfp-close:focus,
.mfp-close:hover {
  opacity: 1;
}
.mfp-wrap {
  z-index: 1043;
  outline: 0 !important;
  -webkit-backface-visibility: hidden;
}
.mfp-container {
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}
.mfp-container:before {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.mfp-align-top .mfp-container:before {
  display: none;
}
.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}
.mfp-ajax-holder .mfp-content,
.mfp-inline-holder .mfp-content {
  width: 100%;
  cursor: auto;
}
.mfp-ajax-cur {
  cursor: progress;
}
.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}
.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}
.mfp-auto-cursor .mfp-content {
  cursor: auto;
}
.mfp-arrow,
.mfp-close,
.mfp-counter,
.mfp-preloader {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.mfp-loading.mfp-figure {
  display: none;
}
.mfp-hide {
  display: none !important;
}
.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}
.mfp-preloader a {
  color: #ccc;
}
.mfp-close,
.mfp-preloader a:hover {
  color: #fff;
}
.mfp-s-error .mfp-content,
.mfp-s-ready .mfp-preloader {
  display: none;
}
button.mfp-arrow,
button.mfp-close {
  overflow: visible;
  cursor: pointer;
  background: 0 0;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: 0;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.mfp-close {
  cursor: pointer !important;
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  padding: 0 0 18px 10px;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}
.mfp-close:active {
  top: 1px;
}
.mfp-close-btn-in .mfp-close {
  color: #333;
}
.mfp-iframe-holder .mfp-close,
.mfp-image-holder .mfp-close {
  color: #fff;
  right: -20px;
  padding-right: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  top: 20px;
  background: #000;
  line-height: 40px;
  border-radius: 2px;
}
.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}
.mfp-figure,
img.mfp-img {
  line-height: 0;
}
.mfp-arrow {
  position: absolute;
  margin: -55px 0 0;
  top: 50%;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}
.mfp-arrow:active {
  margin-top: -54px;
}
.mfp-arrow:after,
.mfp-arrow:before {
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 25px;
  margin-left: 35px;
}
.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 22px;
}
.mfp-arrow::before {
  width: 30px;
  height: 60px;
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
}
.mfp-arrow-left::after,
.mfp-arrow-right:after {
  width: 10px;
  height: 10px;
  border-top: 2px solid #fff;
}
.mfp-arrow:hover::before {
  background: rgba(0, 0, 0, 1);
}
.mfp-arrow-left::after {
  border-left: 2px solid #fff;
  margin-left: 31px;
  transform: rotate(-45deg);
}
.mfp-arrow-left:after {
  margin-left: 36px;
}
.mfp-arrow-left:before {
  margin-left: 25px;
}
.mfp-arrow-right {
  right: 0;
}
.mfp-arrow-right:after {
  border-right: 2px solid #fff;
  margin-left: 41px;
  transform: rotate(45deg);
}
.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}
.mfp-image-holder .mfp-content,
img.mfp-img {
  max-width: 100%;
}
.mfp-iframe-holder .mfp-close {
  top: -40px;
}
.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
}
.mfp-figure:after,
img.mfp-img {
  width: auto;
  height: auto;
  display: block;
}
img.mfp-img {
  box-sizing: border-box;
  padding: 40px 0;
  margin: 0 auto;
}
.mfp-figure:after {
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  right: 0;
  z-index: -1;
  background: #444;
}
.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}
.mfp-figure figure {
  margin: 0;
}
.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}
.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
}
.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}
@media screen and (max-width: 800px) and (orientation: landscape),
  screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

/*--------------------------------------------------
	Tool Tips
---------------------------------------------------*/

[data-tooltip] {
  position: relative;
}
[data-tooltip]::after,
[data-tooltip]::before {
  position: absolute;
  display: block;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  line-height: 12px;
}
[data-tooltip]::before {
  content: attr(data-tooltip);
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  color: #000;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  text-decoration: none;
  text-align: left;
}
.light-content [data-tooltip]::before {
  color: #fff;
}
[data-tooltip]::after {
  width: 0;
  height: 0;
  border: 6px solid transparent;
  content: "";
}
[data-tooltip]:hover::after,
[data-tooltip]:hover::before {
  opacity: 1;
}
[data-tooltip][data-placement="left"]::before {
  top: 32%;
  right: 100%;
  margin-right: 35px;
}
[data-tooltip][data-placement="left"]:hover::before {
  margin-right: -2px;
  -webkit-transition: all 0.3s ease-out 0.5s;
  transition: all 0.3s ease-out 0.5s;
}
[data-tooltip][data-placement="top"]::before {
  top: 16%;
  right: 100%;
  margin-right: 0;
  width: 200px;
  height: 16px;
  transform: translateX(147px) translateY(-170px) rotate(-90deg);
}
[data-tooltip][data-placement="top"]:hover::before {
  transform: translateX(147px) translateY(-140px) rotate(-90deg);
  -webkit-transition: all 0.3s ease-out 0.5s;
  transition: all 0.3s ease-out 0.2s;
}
[data-tooltip][data-placement="right"]::before {
  top: 32%;
  left: 100%;
  margin-left: 35px;
}
[data-tooltip][data-placement="right"]:hover::before {
  margin-left: -2px;
  -webkit-transition: all 0.3s ease-out 0.5s;
  transition: all 0.3s ease-out 0.5s;
}
@media only screen and (max-width: 479px) {
  [data-tooltip]:hover::after,
  [data-tooltip]:hover::before {
    opacity: 0;
  }
}

/*--------------------------------------------------
	Social Share
---------------------------------------------------*/

.jssocials-shares {
  margin: 0.2em 0;
}
.jssocials-shares * {
  box-sizing: border-box;
}
.jssocials-share {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}
.jssocials-share:last-child {
  margin-right: 0;
}
.jssocials-share-logo {
  width: 1em;
  vertical-align: middle;
  font-size: 1.5em;
}
img.jssocials-share-logo {
  width: auto;
  height: 1em;
}
.jssocials-share-link {
  display: block;
  text-align: center;
  text-decoration: none;
  width: 60px;
  height: 60px;
  line-height: 60px;
}
.jssocials-share-link.jssocials-share-link-count {
  padding-top: 0.2em;
}
.jssocials-share-link.jssocials-share-link-count .jssocials-share-count {
  display: block;
  font-size: 0.6em;
  margin: 0 -0.5em -0.8em;
}
.jssocials-share-link.jssocials-share-no-count {
  padding-top: 0.5em;
}
.jssocials-share-link.jssocials-share-no-count .jssocials-share-count {
  height: 1em;
}
.jssocials-share-label {
  padding-left: 0.3em;
  vertical-align: middle;
}
.jssocials-share-count-box {
  display: inline-block;
  height: 1.5em;
  padding: 0 0.3em;
  line-height: 1;
  vertical-align: middle;
  cursor: default;
}
.jssocials-share-count-box.jssocials-share-no-count {
  display: none;
}
.jssocials-share-count {
  line-height: 1.5em;
  vertical-align: middle;
}

/*--------------------------------------------------
	Justified Galler
---------------------------------------------------*/

.justified-gallery {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.justified-gallery > a,
.justified-gallery > div,
.justified-gallery > figure {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  filter: "alpha(opacity=10)";
  opacity: 0.1;
  margin: 0;
  padding: 0;
}
.justified-gallery > a > a > img,
.justified-gallery > a > img,
.justified-gallery > div > a > img,
.justified-gallery > div > img,
.justified-gallery > figure > a > img,
.justified-gallery > figure > img {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  padding: 0;
  border: none;
  filter: "alpha(opacity=0)";
  opacity: 0;
}
.justified-gallery > a > .caption,
.justified-gallery > div > .caption,
.justified-gallery > figure > .caption {
  display: none;
  position: absolute;
  bottom: 0;
  padding: 5px;
  background-color: #000;
  left: 0;
  right: 0;
  margin: 0;
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  font-family: sans-serif;
}
.justified-gallery > .entry-visible {
  filter: "alpha(opacity=100)";
  opacity: 1;
  background: 0 0;
}
.justified-gallery > .entry-visible > a > img,
.justified-gallery > .entry-visible > img {
  filter: "alpha(opacity=100)";
  opacity: 1;
  -webkit-transition: opacity 0.5s ease-in;
  -moz-transition: opacity 0.5s ease-in;
  -o-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in;
}
.justified-gallery > .jg-filtered {
  display: none;
}
.justified-gallery > .spinner {
  position: absolute;
  bottom: 0;
  margin-left: -24px;
  padding: 10px 0;
  left: 50%;
  filter: "alpha(opacity=100)";
  opacity: 1;
  overflow: initial;
}
.justified-gallery > .spinner > span {
  display: inline-block;
  filter: "alpha(opacity=0)";
  opacity: 0;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-color: #fff;
  border-radius: 6px;
}
