@media only screen and (max-width: 321px) {
  .input-wrapper {
    display: block !important;
  }
  .label {
    display: block;
    padding: 9px;
  }
  .card-user {
    padding: 0 !important;
  }
  .card-body {
    padding: 20px !important;
  }
}
.input-wrapper {
  display: -ms-grid;
  display: grid;
  width: 100%;
  -ms-grid-columns: 120px 1fr;
  grid-template-columns: 120px 1fr;
  -webkit-column-gap: 5px;
  column-gap: 5px;
  margin: 5px 0 5px 0;
}
.dx-texteditor.dx-editor-outlined {
  width: 100%;
}
.dx-texteditor.dx-editor-filled {
  width: 1000%;
}
