html,
body {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 15px;
  height: 100%;
  width: 100%;
  color: #999;
  background-color: #fff;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

html {
  box-sizing: border-box;
  margin-right: 0px !important;
  overflow: visible !important;
}

body {
  overflow: hidden;
  overflow-y: scroll;
  -webkit-transition: opacity 0.2s ease-in-out 0.2s;
  transition: opacity 0.2s ease-in-out 0.2s;
}

.smooth-scroll {
  overflow: hidden;
}

body.hidden {
  opacity: 0;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

audio {
  display: inline-block;
  max-width: 100%;
}

address {
  font-style: italic;
  margin-bottom: 24px;
}

abbr[title] {
  border-bottom: 1px dotted #2b2b2b;
  cursor: help;
}

b,
strong {
  font-weight: 600;
  color: #000;
}
body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: #0000009e;
}

/* Firefox */
body {
  scrollbar-width: thin;
  scrollbar-color: black;
}

/* Edge and IE */
body {
  -ms-overflow-style: scrollbar;
}
