	#app {
		top: 0;
		left: 0;
		position: fixed;
		width: 100%;
		height: 100vh;
		overflow: hidden;
		pointer-events: none;
		opacity:0;
		-webkit-transition: all 0.1s ease-in-out 0.3s;
		transition: all 0.1s ease-in-out 0.3s;
	}
	
	.load-project-thumb #app, .load-project-thumb-with-title #app, .load-project-thumb-with-title-and-scale #app {
		opacity:1;
	}
	
	.grid__item-img--large {
		pointer-events: none;
		position: absolute ;
		opacity: 0;
	}
	
	.grid__item-hidden {
		pointer-events: none;
		position: fixed;
		opacity: 0;
	}
	
	#project-holder {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index:100;
		pointer-events:none;
	}
	
	#project-holder.open {
		pointer-events:initial;
	}
	
	#showcase-height {
		position:relative;
		width:100vw;
		height:0;
	}
	
	#project-holder #main, #project-holder #main-page-content {
		opacity:1;
	}


	.thumb-container {
		visibility: hidden;
		height:100vh;
		width: 100%;
		left:0px;
		position: fixed;
		top:0;
		z-index:100;
		opacity:1;
		-webkit-transform: scale(1.05);
		transform: scale(1.05);	
		background-color:#161717;
	}
	
	
	.thumb-page {
		cursor: pointer;
		position: absolute;
		height:100vh;
		width:100%;
		top:0;
		left:0px;
		position: fixed;
		visibility:hidden;
		background-position:center center;
		background-size:cover;
		background-repeat:no-repeat;
	}
	
	.thumb-page::after {
		content: "";
		width: 100%;
		height: 60%;
		position: absolute;
		bottom: 0;
		left: 0;
		pointer-events: none;
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
	}
	
/*--------------------------------------------------
	02. Filters
---------------------------------------------------*/		
	
		
	#show-filters {
		font-weight: 600;
		font-family: 'Poppins', sans-serif;
		font-size: 12px;
		color:#000;
		opacity: 0;
		display: block;
		cursor: pointer;
		position: fixed;
		left: 48px;
		bottom: 30px;
		height: 80px;
		width: 80px;
		background: none;
		transform-origin: 0 0;
		z-index:100;
		pointer-events:none;
	}
	
	.light-content #show-filters {
		color:#fff;
	}
	
	#show-filters.enabled {
		pointer-events:initial;
	}
	
	.show-filters-wrap {
		width: 80px;
		height: 80px;
		float: right;
		top: 0px;
		display: flex;
		position: relative;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
	
	.open-filters {
		height: 46px;		
		width: 46px;
		text-align:center;
		line-height:46px;
		font-size:16px;
		cursor: pointer;
		position: relative;		
	}
	
	#filters-overlay {
		position:fixed;
		z-index:15;
		height:100vh;
		width:100%;
		top:0;
		left:0;
		right:0;
		bottom:0;
		opacity:0;
		visibility:hidden;
	    box-sizing: border-box;
		-webkit-transition: all 0.1s ease-in-out 0.6s;
		transition: all 0.1s ease-in-out 0.6s;
	}
	
	#filters-overlay.active {
		opacity:1;
		visibility:visible;
		-webkit-transition: all 0.3s ease-in-out 0.2s;
		transition: all 0.3s ease-in-out 0.2s;
	}
	
	#close-filters {
		position: fixed !important;
		height:100vh;
		width:100vw;
		top:0;
		left:0;
		right:0;
		bottom:0;
	}
	
	#filters-overlay .outer {
		width: 100%;
		margin: 0 auto;
		padding: 80px;
		padding-top:160px;
		box-sizing: border-box;
	}
	
	#filters-overlay .inner {
		vertical-align: middle;
		position: relative;
		pointer-events: none;
		text-align: center;
	}
	
	.filters-wrap {
		position: relative;
		width: 100%;
		max-height: 0px;
		opacity:0;
		margin-top: 100px;
		transition: all 0.6s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.2s;
		-webkit-transition: all 0.6s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.2s;
	}
	
	.filters-wrap.active {
		opacity: 1;
		max-height: 100px;
		transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
		-webkit-transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
	}
	
	#filters {
		height: auto;
		z-index: 15;
		position: relative;
		width: auto;
		display: inline-block;
		padding: 0;
		pointer-events: initial;
		margin: 0;
	}
	
	#filters li {
		display: block;
		opacity: 1;
		position: relative;
		margin: 15px 0;
		opacity: 0;
		text-align: center;
	}
	
	#filters li a {
		display: inline-block;
		color: #000;
		font-weight: 500;
		font-size:48px;
		line-height: 60px;
		z-index: 15;
		position: relative;
		-webkit-transition: all 0.15s ease-in-out;
		transition: all 0.15s ease-in-out;
	}
	
	.light-content #filters li a {
		color: #fff;
	}
	
	#filters:hover li a {
		opacity:0.4;
	}
	
	#filters li a:hover {
		opacity:1;
	}
	
	
/*--------------------------------------------------
	09. Portfolio Isotope Transitions
---------------------------------------------------*/

	.isotope, .isotope .isotope-item {
		-webkit-transition-duration: 1.8s;
		-moz-transition-duration: 1.8s;
		-ms-transition-duration: 1.8s;
		-o-transition-duration: 1.8s;
		transition-duration: 1.8s;
	}
	
	.isotope {
		-webkit-transition-property: height, width;
		-moz-transition-property: height, width;
		-ms-transition-property: height, width;
		-o-transition-property: height, width;
		transition-property: height, width;
	}
	
	.isotope .isotope-item {
		-webkit-transition-property: -webkit-transform, opacity;
		-moz-transition-property: -moz-transform, opacity;
		-ms-transition-property: -ms-transform, opacity;
		-o-transition-property: -o-transform, opacity;
		transition-property: transform, opacity;
	}
	
/*--------------------------------------------------
	03. Portfolio Layout
---------------------------------------------------*/	
	
	
	.scale-up .portfolio-wrap {		
		pointer-events:none;
	}
		
	.portfolio-wrap {		
		position:relative;
		margin:0 auto;
		width: 100%;
		padding:0;
		box-sizing:border-box;
		min-height:50vh;
	}
	
	.portfolio {
		position: relative;
		margin: 0 auto;
		box-sizing: border-box;
		-webkit-transition: height 0.5s ease-out;
		transition: height 0.5s ease-out;
	}
	
	.portfolio:after {
		content:""; 
		display:table; 
		clear:both;
	}
	
	.portfolio .item:last-child {
		margin-bottom:0!important;
	}
	
	/*-- Spaced Grid --*/
	
	.landing-grid .button-wrap {
		opacity: 1;
		transform: translate3d(0px, 0px, 0px);
		position: absolute;
		left: calc(100% - (50% - 450px));
		bottom: 35px;
		height: 60px;
		float: none;
		top: auto;
	}
	
	.landing-grid:before {	
		content:"";
		position:absolute;
		bottom:0;
		width:100%;
		height:20vw;
		background-color:#eee;
		z-index:0;
		-webkit-transition: opacity 0.15s ease-out;
		transition: opacity 0.15s ease-out;
	}
	
	.light-content .landing-grid:before {
		background-color:rgb(34, 34, 34);
	}
	
	.show-loader .landing-grid:before {
		opacity:0;
	}		
	
	.item {
		display: block;
		position: relative;
		box-sizing: border-box;
		z-index: 0;
		margin:0px;
		width: 50%;
		height: 32vw;
	}
	
	.landing-grid .item:nth-child(1) {
		width: calc(50% - 390px);
		margin-top:20vw;
		height: 20vw;
	}
	
	.landing-grid .item:nth-child(2) {
		width: calc(100% - (50% - 390px));
		margin-top:0;
		height: 40vw;
	}
	
	.landing-grid .item:nth-last-child(2) {
		width: calc(100% - (50% - 390px));
		margin-top:0;
		height: 40vw;
	}
	
	.landing-grid .item:nth-last-child(1) {
		width: calc(50% - 390px);
		margin-top:0;
		height: 20vw;
	}
	
	.metro-grid .item:nth-child(1) {
		margin-top:20vw!important;
		height: 20vw!important;
	}
	
	.metro-grid .item:nth-of-type(6n+1) {
		width: calc(50% - 390px);
		margin-top:0;
		height: 40vw;
	}
	
	.metro-grid .item:nth-of-type(6n+2) {
		width: calc(100% - (50% - 390px));
		margin-top:0;
		height: 40vw;
	}
	
	.metro-grid .item:nth-of-type(6n+3) {		
		margin:0px;
		width: 50%;
		height: 32vw;
	}
	
	.metro-grid .item:nth-of-type(6n+4) {
		margin:0px;
		width: 50%;
		height: 32vw;
	}
	
	.metro-grid .item:nth-of-type(6n+5) {
		width: calc(100% - (50% - 390px));
		margin-top:0;
		height: 40vw;
	}
	
	.metro-grid .item:nth-of-type(6n+6) {
		width: calc(50% - 390px);
		margin-top:0;
		height: 40vw;
	}
	
	.metro-grid .item:nth-of-type(6n+6):nth-last-child(1) {
		margin-top:0;
		height: 20vw;
	}
	
	.item-parallax {
		height: 100%;
		width: 100%;
		position: absolute;
	}
	
	.item-appear {
		display: block;
		position: relative;
		box-sizing: border-box;
		z-index: 0;
		width: 100%;
		height: 100%;
		overflow:hidden;
					
	}
	
	.item-image::after {
		content: "";
		opacity:0;
		width: 100%;
		height: 60%;
		position: absolute;
		bottom: 0;
		left: 0;
		pointer-events: none;
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
		-webkit-transition: all 0.6s ease-in-out;
		transition: all 0.6s ease-in-out;
	}
	
	.load-project-thumb .item-image::after {
		content: "";
		opacity:1;
		width: 100%;
		height: 60%;
		position: absolute;
		bottom: 0;
		left: 0;
		pointer-events: none;
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
		-webkit-transition: all 0.6s ease-in-out;
		transition: all 0.6s ease-in-out;
	}

	.item-content {
		width: 100%;
		height: 100%;
		position:relative;
		opacity:1; 
	}
	
	.fade-scalein-effect .item-content {
		opacity:0; 
		-webkit-transform:scale(0.8);
		transform: scale(0.8);
		-webkit-transition: all 1s cubic-bezier(.165,.84,.44,1) 0.2s;	
		transition: all 1s cubic-bezier(.165,.84,.44,1) 0.2s;	
	}
	
	.fade-scalein-effect .item.active .item-content {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
		position:absolute;
		right:0;	
	}
	
	.fade-scaleout-effect .item-content {
		opacity:0; 
		-webkit-transform:scale(1.2);
		transform: scale(1.2);
		-webkit-transition: all 1s cubic-bezier(.165,.84,.44,1) 0.2s;	
		transition: all 1s cubic-bezier(.165,.84,.44,1) 0.2s;	
	}
	
	.fade-scaleout-effect .item.active .item-content {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
		position:absolute;
		right:0;	
	}
	
	.width-scale-effect .item-content {
		-webkit-transform:scale(1.2);
		transform: scale(1.2);
		-webkit-transition: all .6s cubic-bezier(0.54, 0.49, 0.2, 1.01);
		-o-transition: all .6s cubic-bezier(0.54, 0.49, 0.2, 1.01);
		transition: all .6s cubic-bezier(0.54, 0.49, 0.2, 1.01);	
	}
	
	.width-scale-effect .item.active .item-content {
		-webkit-transform: scale(1);
		transform: scale(1);	
	}
	
	.width-scale-effect .item-content:after {
		content: "";
		width: 100%;
		height: 100%;
		background-color:#141414;
		position:absolute;
		top:0;
		right:0;
		-webkit-transition: all .6s cubic-bezier(0.54, 0.49, 0.2, 1.01);
		-o-transition: all .6s cubic-bezier(0.54, 0.49, 0.2, 1.01);
		transition: all .6s cubic-bezier(0.54, 0.49, 0.2, 1.01);	
	}
	
	.width-scale-effect .item.active .item-content:after {
		width: 0;
	}
	
	.height-scale-effect .item-content {
		opacity: 0;
		-webkit-transition:translateY(220px) scaleY(1.3);
		transform: translateY(220px) scaleY(1.3);
		-webkit-transition: transform 1s cubic-bezier(.165,.84,.44,1), opacity 1s cubic-bezier(.165,.84,.44,1);	
		transition: transform 1s cubic-bezier(.165,.84,.44,1), opacity 1s cubic-bezier(.165,.84,.44,1);		
	}
	
	.height-scale-effect .item.active .item-content {
		opacity: 1;
		-webkit-transition:translateY(0px) scaleY(1);
		transform: translateY(0px) scaleY(1);	
	}
	
	.item-wrap {
		width: 100%;
		height: 100%;
		position:absolute;	 				
	}
	
	.item-wrap-image {
		width: 100%;
		height: 100%;
		position:relative;
		z-index:0;
		overflow:hidden;
		-webkit-transform:scale(1.05);
		transform: scale(1.05);
		-webkit-transition: all 1s cubic-bezier(.165,.84,.44,1) 0s;	
		transition: all 1s cubic-bezier(.165,.84,.44,1) 0s;			
	}
	
	.item:hover .item-appear .item-wrap-image, .above  .item-wrap-image {		
		-webkit-transform:scale(1);
		transform: scale(1);			
	}
	
	.item-image {
		width:100%;
		height: 100%;
		position:relative;
		display:block;
		z-index:100;	
		object-position: center; 
		object-fit: cover;
	}
	
	.portfolio .item-image {
		cursor:pointer;		
	}
	
	.above.item {
		opacity: 1 !important;
	}
	
	.item-caption {
		z-index: 10;
		position: absolute;
		bottom: 0px;
		height: auto;
		padding:50px 60px;
		box-sizing: border-box;
		width: 100%;
		cursor:default;
		pointer-events: none;
		opacity:0;
		-webkit-transition: all 0.2s ease-out 0.4s;
		transition: all 0.2s ease-out 0.4s;	
	}
	
	.item.active .item-caption {
		opacity: 1;
	}
	
	.item-title {		
		margin: 0;
		font-weight: 500;
		font-size:24px;
		transform: translateY(20px);
		-webkit-transform: translateY(20px);
		margin-left:-1px;
		display: block;
		position:relative;
		color:#000;
		-webkit-transition: all 0.2s ease-out;
		transition: all 0.2s ease-out;		
	}
	
	.light-content .item-title {
		color:#000;
	}
	
	.change-header .item-title {
		color:#fff;
	}
	
	.light-content .change-header .item-title {
		color:#fff;
	}
	
	.item:hover .item-title {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
	}
	
	.portfolio .item:first-child .item-title::after {
		content: "New";
		font-family: 'Poppins', sans-serif;
		font-weight: 300;
		font-size: 10px;
		position: absolute;
		font-weight: 600;
		margin-left: 10px;
		color: #FF1B0B;
		top: -6px;
	}
	
	.item-cat {
		display:block;
		font-size: 12px;
		font-weight: 500;
		opacity: 0;
		line-height: 20px;
		overflow:hidden;
		-webkit-transform: translateY(15px);
		transform: translateY(15px);
		color:#000;
		-webkit-transition: all 0.1s ease-out;
		transition: all 0.1s ease-out;		
	}
	
	.change-header .item-cat {
		color:#fff;
	}
	
	.light-content .change-header .item-cat {
		color:#fff;
	}
	
	.light-content .item-cat {
		color:#000;
	}
	
	.item:hover .item-cat {
		-webkit-transform: translateY(5px);
		transform: translateY(5px);
		opacity:1;
		-webkit-transition: all 0.1s ease-out 0.1s;
		transition: all 0.1s ease-out 0.1s;
	}


/*--------------------------------------------------
	Project Page Navigation
---------------------------------------------------*/	
	
	#project-nav {
		height: 100vh;
		width: 100%;
		color: #000;
		position: absolute;
		margin-bottom: 0;
		bottom: -100vh;
		overflow:hidden;
	}
	
	.light-content #project-nav {
		color: #fff;
	}
	
	.next-project-wrap {
		display: table;
		width: 100%;
		margin: 0 auto;
		height: 100%;
		position: relative;
		box-sizing: border-box;
		opacity:0;
		top:-40%;
		z-index:10;
	}
	
	.next-project-image-wrapper {
		background-color: #171717;
		position: fixed;
		width: 100%;
		height: 100%;
		top:0;
		left:0;
	}
	
	.next-project-image {
		position: fixed;
		top:60%;
		opacity:0;
		left: 0;
		height: 100%;
		width: 100%;
		overflow:hidden;
	}
	
	.load-project-thumb-with-title .next-project-image {
		z-index:10;
	}
	
	.next-project-image.temporary {
		top:0!important;
	}
	
	.next-project-image.visible {
		opacity:1!important;
	}
	
	.next-project-image-bg {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-size: cover;
		background-position:center center;
		background-repeat: no-repeat;
		opacity:1;
		z-index:1;
		-webkit-transform: scale(1);
		transform: scale(1);	
	}
	
	.next-project-title {
		display: table;
		width: 100%;
		padding:20px;
		margin: 0 auto;
		height: 100%;
		position: relative;
		box-sizing: border-box;
		opacity:0;
		top:-40%;
		z-index:10;
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}
	
	.app-canvas .next-project-title {
		padding: 0px;
		max-width:none;
	}
	
	.next-project-caption {
		display: table-cell;
		vertical-align: middle;
		box-sizing: border-box;
		vertical-align: middle;
		text-align:left;
		
	}
	
	.next-caption-wrapper {
		display: table;
		margin: 0 auto;
		width: 100%;
		position:relative;
		padding: 20px;
		padding-left:calc(50% - 390px);
		max-width:none;
		box-sizing:border-box;
	}
	
	#project-nav .next-ajax-link-project {
		display: block;
		margin: 0 auto;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index:10;
	}
	
	.next-caption {
		display:block;
		position:relative;
		z-index:1;
	}

	.main-title {
		display:block;
		width:auto;
		font-size:60px;
		line-height:70px;
		font-weight: 500;
		z-index:10;
		color:#000;		
		position:relative;
		margin:0 auto;
		margin-bottom: 20px;
		margin-left: -3px;
	}
	
	.light-content .main-title {
		color:#fff;		
	}
	
	.main-title span {
		background-image: linear-gradient(transparent calc(100% - 3px), #000 1px);
		background-size: 0;
		background-repeat: no-repeat;
		display: inline;
		transition: 0.5s ease;
	}
	
	.light-content .main-title span {
		background-image: linear-gradient(transparent calc(100% - 3px), #fff 1px);
	}
  
  	.main-title:hover span {    
      	background-size: 100%;
    }
	
	.page-is-changing .main-title span {
		background-size: 0;
		transition: 0.3s ease;
	}
	
	.next-title {
		margin-bottom: 0;
		line-height: 20px;
		height:20px;
		overflow:hidden;
		position: relative;
		left: 0;
		display: block;
		font-size:16px;
		font-weight: 600;		
		transform: translateY(-20px);
		-webkit-transform: translateY(-20px);
	}
	
	.light-content .next-title {
		color:#FFF;		
	}
	
	.next-subtitle-name {
		margin-bottom: 0;
		opacity: 1;
		line-height: 20px;
		height:20px;
		overflow:hidden;
		position: relative;
		left: 0;
		display: block;
		font-size:16px;
		font-weight: 600;
		font-family: 'Poppins', sans-serif;
		color: #000;
	}
	
	.next-subtitle-name {
		transform: translateY(50px) rotate(-7deg);
		-webkit-transform: translateY(50px) rotate(-7deg);
		opacity:0;
	}
	
	.light-content .next-subtitle-name {
		color: #fff;
	}
	
	#project-nav #itemsWrapperLinks {
		height:auto;
	}
	
	#project-nav #itemsWrapper {
		z-index:10!important;
		position:relative;
		padding: 0;
		max-width:1000px;
		margin: 0 auto;
	}
	
	#project-nav .item .item-title {
		-webkit-transform: translateY(10px);
		transform: translateY(10px);
		font-size:14px;
	}
	
	#project-nav .item-wrap-image {
		-webkit-transform:scale(1);
		transform: scale(1);	
	}
	
	#project-nav .item-image {
		opacity: 1 !important;
	}
	
	#project-nav .item-caption {
		bottom:-5px;
		text-align:center;
	}
	
	
/*--------------------------------------------------
	Responsive
---------------------------------------------------*/	




@media only screen and (max-width: 1466px) {
	
	#filters-overlay .outer {
		padding: 60px;
	}
	
	#show-filters {
		left: 26px;
		bottom: 20px;
	}
	
	#main .landing-grid .large-btn .button-text {
		font-size: 12px;
		font-weight: 600;
		margin: 15px 0;
	}
	
	
}


@media only screen and (max-width: 1300px) {
	
	.landing-grid::before {
		height: calc(50vw + 60px);
	}
	
	.landing-grid .item:nth-child(1) {
		width: calc(100% - (50% - 390px));
		margin-top: 0;
		margin-left:calc(50% - 390px);
		height: 50vw;
	}
	
	.landing-grid .item:nth-child(2) {
		margin: 0px;
		width: 70%;
		height: 40vw;
	}
	
	.landing-grid .item:nth-last-child(2) {
		margin: 0px;
		width: 30%;
		height: 40vw;
	}
	
	.landing-grid .item:nth-last-child(1) {
		width: calc(100% - (50% - 390px));
		margin-top: 0;
		height: 50vw;
	}
	
	.metro-grid .item:nth-child(1) {
		width: calc(100% - (50% - 390px))!important;
		margin-top: 0!important;
		margin-left:calc(50% - 390px)!important;
		height: 50vw!important;
	}
	
	.metro-grid .item:nth-of-type(6n+1) {
		margin: 0px;
		width: 30%;
		height: 40vw;
	}
	
	.metro-grid .item:nth-of-type(6n+2) {
		margin: 0px;
		width: 70%;
		height: 40vw;
	}
	
	.metro-grid .item:nth-of-type(6n+3) {
		margin: 0px;
		width: 30%;
		height: 40vw;
	}
	
	.metro-grid .item:nth-of-type(6n+4) {
		margin: 0px;
		width: 30%;
		height: 40vw;
	}
	
	.metro-grid .item:nth-of-type(6n+5) {
		margin: 0px;
		width: 70%;
		height: 40vw;
	}
	
	.metro-grid .item:nth-of-type(6n+6) {
		margin: 0px;
		width: 70%;
		height: 40vw;
	}
	
	.metro-grid .item:nth-of-type(6n+6):nth-last-child(1) {
		width: calc(100% - (50% - 390px));
		margin-top: 0;
		height: 50vw;
	}
	
	.see-all-works {
		display: table;
		max-width: 820px;
		width: 100%;
		margin: 0 auto;
		position: relative;
		padding: 0 20px;
		box-sizing: border-box;
	}
	
	.landing-grid .button-wrap {
		opacity: 1;
		transform: translate3d(0px, 0px, 0px);
		position: relative;
		left: auto;
		bottom: auto;
		height: 60px;
		float: none;
		top: 20px;
	}
	
}

@media only screen and (max-width: 1024px) {	
	
	#filters-overlay .outer {
		padding: 40px;
		padding-bottom:80px;
	}
	
	#filters li a {
		font-size: 24px;
		line-height: 40px;
	}
	
	#show-filters {
		left: 22px;
		bottom: 14px;
		height: 46px;
		width: 46px;
	}
	
	#show-filters[data-tooltip][data-placement="top"]::before {
		display:none;
	}
	
	.show-filters-wrap {
		width: 46px;
		height: 46px;
	}
	
	.landing-grid .item:nth-child(1) {
		width: 100%;
		margin-top: 0;
		margin-left:0;
		height: 50vw;
	}
	
	.landing-grid .item:nth-child(2) {
		width: 50%;
		margin-top: 0;
		height: 30vw;
	}
	
	.landing-grid .item:nth-last-child(2) {
		width: 50%;
		margin-top: 0;
		height: 30vw;
	}
	
	.landing-grid .item:nth-last-child(1) {
		width: 100%;
		margin-top: 0;
		margin-left:0;
		height: 50vw;
	}
	
	.metro-grid .item:nth-child(1) {
		width: 100%!important;
		margin-top: 0!important;
		margin-left:0!important;
		height: 50vw!important;
	}
	
	.metro-grid .item {
		width: 50%!important;
		margin-top: 0!important;
		height: 30vw!important;
	}
	
	.metro-grid .item:nth-of-type(6n+6):nth-last-child(1) {
		width: 100%!important;
		margin-top: 0!important;
		margin-left:0!important;
		height: 50vw!important;
	}
	
	.item-caption {
		padding: 30px 40px;
	}
	
	.see-all-works {
		padding: 0px 40px;
	}
	
	.next-caption-wrapper {
		padding: 0 40px;
		max-width: 860px;
	}
	
}

@media only screen and (max-width: 767px) {
	
	#filters-overlay .outer {
		padding: 30px;
		padding-bottom:80px;
	}
	
	#show-filters {
		left: 12px;
		bottom: 10px;
	}
	
	.landing-grid .item, .metro-grid .item, .metro-grid .item:nth-child(1), .metro-grid .item:nth-of-type(6n+6):nth-last-child(1) {
		width: 100%!important;
		margin-top: 0!important;
		height: 64vw!important;
	}
	
	.item-caption {
		padding: 30px 30px;
	}
	
	.see-all-works {
		padding: 0px 30px;
	}
	
	.next-caption-wrapper {
		padding: 0 30px;
	}
	
}

@media only screen and (max-width: 479px) {
	
	#filters-overlay .outer {
		padding: 20px;
		padding-bottom:80px;
	}
	
	#show-filters {
		left: 2px;
		bottom: 2px;
	}
	
	.portfolio {
		overflow:hidden;
	}	
	
	.item-caption {
		padding: 30px 20px;
	}
	
	.item-title {
    	font-size: 18px;
	}
	
	.see-all-works {
		padding: 0px 20px;
	}
	
	.next-caption-wrapper {
		padding: 0 20px;
	}
		
}			
	