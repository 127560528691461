.un {
  color: rgb(38, 50, 56);
  font-size: 14px;
  letter-spacing: 1px;
  min-width: 300px;
  /* background: rgba(136, 126, 126, 0.04); */
  padding: 10px 20px;
  /* border: none; */
  border-radius: 4px;

  outline: none;
  box-sizing: border-box;
  border: 0.5px solid rgb(0 0 0 / 30%);
  margin: 10px 0px;
  display: flex;
}
